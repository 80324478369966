import Header from '../../components/header'
import { FluentProvider, ToggleButton, makeStyles, shorthands, teamsLightTheme } from '@fluentui/react-components'
import { useAppStateStore } from '../../store'
import { useEffect, useState } from 'react'
import React from 'react'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import type { SelectTabData, SelectTabEvent, TabValue } from '@fluentui/react-components'
import { DonutChart, IChartProps, IChartDataPoint, DataVizPalette, getColorFromToken } from '@fluentui/react-charting'
import { DocumentCard } from '@fluentui/react/lib/DocumentCard'
import EmptyPage from './EmptyPage'
import { apiGet } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'
import { Filter24Regular } from '@fluentui/react-icons'

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...shorthands.padding('10px', '10px'),
    rowGap: '10px',
  },
})
const DonutChartMobile: React.FC<{ data: IChartDataPoint[] }> = ({ data }) => {
  const chartData: IChartProps = {
    chartTitle: 'Donut chart mobile',
    chartData: data,
  }
  return (
    <div style={{ marginTop: '50px' }}>
      <DonutChart
        height={166} // Smaller size for mobile
        width={200}
        data={chartData}
        hideLabels={true}
        innerRadius={40}
        showLabelsInPercent={true}
      />
    </div>
  )
}
const DonutChartLarge: React.FC<{ data: IChartDataPoint[] }> = ({ data }) => {
  const chartData: IChartProps = {
    chartTitle: 'Donut chart large',
    chartData: data,
  }

  // Dynamically calculate the chart's width and height based on screen size
  const [chartSize, setChartSize] = useState({ width: 600, height: 500 })

  const debounce = (func: () => void, timeout = 200) => {
    let timer: NodeJS.Timeout
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func()
      }, timeout)
    }
  }

  // Function to update chart size based on window size
  const updateChartSize = () => {
    const width = window.innerWidth

    if (width <= 768) {
      // Mobile size
      if (width >= 391 && width <= 550) {
        setChartSize({ width: 300 * 0.7, height: 300 })
      }
    } else if (width > 768 && width <= 1200) {
      // Tablet size
      setChartSize({ width: width * 0.6, height: 400 })
    } else {
      // Desktop size
      setChartSize({ width: 600, height: 500 })
    }
  }

  useEffect(() => {
    // Debounced resize event handler
    const handleResize = debounce(updateChartSize)

    window.addEventListener('resize', handleResize)
    updateChartSize() // Initial size setup

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <DonutChart
      height={chartSize.height}
      width={chartSize.width}
      data={chartData}
      hideLabels={false}
      innerRadius={105}
      showLabelsInPercent={true}
    />
  )
}
export default function TrendingTopics() {
  const styles = useStyles()
  const [selectedValue, setSelectedValue] = React.useState<TabValue>('7days')
  const [EventActive, SetEventActive] = React.useState(1)
  const [trendingTopics, setTrendingTopics] = useState<any[]>([])
  const [activeButton, setActiveButton] = useState(1)
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 390px)').matches)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value)
  }

  const handleClick = (index: number) => {
    setActiveButton(index)
  }

  useEffect(() => {
    if (activeButton === 1) {
      daysIndex(0)
    } else if (activeButton === 2) {
      daysIndex(1)
    } else if (activeButton === 3) {
      daysIndex(2)
    }
  }, [activeButton])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 390px)').matches)
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const appState = useAppStateStore()
  useEffect(() => {
    getTrendingTopics()
    handleClick(1)
  }, [])

  const getTrendingTopics = async () => {
    try {
      const trendingTopicsResult = await apiGet<any[]>('dashboard/top-topics', appState.identity.accessToken)
      setTrendingTopics(trendingTopicsResult)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const [dynamicData, setDynamicData] = useState<IChartDataPoint[]>([])

  const daysIndex = async (index: number) => {
    if (trendingTopics[index] && trendingTopics[index].events) {
      if (trendingTopics[index].events.length > 0) {
        SetEventActive(1)
        const updatedData = trendingTopics[index].events.map((event: any, index: any) => ({
          legend: event.topic,
          data: event.eventCount,
          color: getColorFromToken(DataVizPalette[`color${index + 1}` as keyof typeof DataVizPalette]),
        }))

        setDynamicData(updatedData)
      } else {
        SetEventActive(0)
      }
    }
  }

  return (
    <FluentProvider theme={teamsLightTheme}>
      <div style={{ backgroundColor: '#EFF5F9' }}>
        <Header />

        <div className='ml-12'>
          <Breadcrumb
            items={[
              { text: 'Home', key: 'home', href: '#/tab' },
              { text: 'Preferences', key: 'preferences', href: '#/preference' },
              { text: 'Trending Interests', key: 'trendingInterests', isCurrentItem: true },
            ]}
          />
        </div>

        <div className='mx-8'>
          {' '}
          <DocumentCard className="p-2 border border-gray-100 rounded-lg flex items-center flex-row gap-4 shadow-md text-nowrap max-w-full !w-screen">
            <div className='flex items-center'>
              <span>
                <Filter24Regular />
              </span>
              <span className='font-medium'>Filter By :</span>
            </div>
            <div className='flex flex-col justify-center xs:flex-row'>
              <ToggleButton
                checked={activeButton === 1}
                onClick={() => {
                  handleClick(1)
                }}
                style={{
                  backgroundColor: activeButton === 1 ? '#1b75d0' : 'white',
                  color: activeButton === 1 ? 'white' : 'black',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                7 Days
              </ToggleButton>
              <ToggleButton
                checked={activeButton === 2}
                onClick={() => {
                  handleClick(2)
                }}
                style={{
                  backgroundColor: activeButton === 2 ? '#1b75d0' : 'white',
                  color: activeButton === 2 ? 'white' : 'black',
                  borderRadius: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                }}
              >
                30 Days
              </ToggleButton>
              <ToggleButton
                checked={activeButton === 3}
                onClick={() => {
                  handleClick(3)
                }}
                style={{
                  backgroundColor: activeButton === 3 ? '#1b75d0' : 'white',
                  color: activeButton === 3 ? 'white' : 'black',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                60 Days
              </ToggleButton>
            </div>
          </DocumentCard>
          <div>
            {activeButton === 1 && (
              <>
                {EventActive == 1 && (isMobile ? <DonutChartMobile data={dynamicData} /> : <DonutChartLarge data={dynamicData} />)}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
          <div>
            {activeButton === 2 && (
              <>
                {EventActive == 1 && (isMobile ? <DonutChartMobile data={dynamicData} /> : <DonutChartLarge data={dynamicData} />)}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
          <div>
            {activeButton === 3 && (
              <>
                {EventActive == 1 && (isMobile ? <DonutChartMobile data={dynamicData} /> : <DonutChartLarge data={dynamicData} />)}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
        </div>
      </div>
    </FluentProvider>
  )
}
