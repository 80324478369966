export function apiGet<T>(url: string, accessToken: string): Promise<T> {
  const apiBaseUrl = process.env.REACT_APP_FUNC_ENDPOINT as string
  if (!apiBaseUrl) {
    throw new Error('invalid apiBaseUrl')
  }

  if (!url) {
    throw new Error('invalid url')
  }

  if (!accessToken) {
    throw new Error('invalid accessToken')
  }

  const requestOptions = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }

  return fetch(`${apiBaseUrl}/${url}`, requestOptions).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json() as Promise<T>
  })
}

//todo optimize
export function apiPostResponse<T>(url: string, accessToken: string, body: any): Promise<T> {
  console.log(process.env)
  const apiBaseUrl = process.env.REACT_APP_FUNC_ENDPOINT as string
  if (!apiBaseUrl) {
    throw new Error('invalid apiBaseUrl')
  }

  if (!url) {
    throw new Error('invalid url')
  }

  if (!accessToken) {
    throw new Error('invalid accessToken')
  }

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-type': 'application/json' },
    body: JSON.stringify(body),
  }

  return fetch(`${apiBaseUrl}/${url}`, requestOptions).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json() as Promise<T>
  })
}

export function apiPost(url: string, accessToken: string, body: any): Promise<void> {
  const apiBaseUrl = process.env.REACT_APP_FUNC_ENDPOINT as string
  if (!apiBaseUrl) {
    throw new Error('invalid apiBaseUrl')
  }

  if (!url) {
    throw new Error('invalid url')
  }

  if (!accessToken) {
    throw new Error('invalid accessToken')
  }

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-type': 'application/json' },
    body: JSON.stringify(body),
  }

  return fetch(`${apiBaseUrl}/${url}`, requestOptions).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
  })
}
