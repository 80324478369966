import {
  Input,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  FluentProvider,
  ToggleButton,
  teamsLightTheme,
  Toaster,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components'
import Header from '../../components/header'

import { DocumentCard } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'

import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { Divider } from '@fluentui/react-components'

import { Archive24Filled } from '@fluentui/react-icons'
import { useEffect, useId, useState } from 'react'
import { useAppStateStore } from '../../store'
import { apiGet, apiPost } from '../../services/fetch/apiClient'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import _ from 'lodash'
import { logger } from '../../components/App'

export default function ManageInterest() {
  const [archive, setarchive] = useState(false)
  const [isHidden, setIsHidden] = useState(true)
  function ShowArchived() {
    setarchive(!archive)
    setIsHidden(!isHidden)
  }

  const [selectedInterests, setSelectedInterests] = useState<Interest[]>([])
  const [archiveInterests, setArchiveInterests] = useState<Interest[]>([])

  type Interest = {
    id: string
    name: string
    userCount: number
    meetingCount: number
  }
  const inputId = useId()
  const appState = useAppStateStore()
  useEffect(() => {
    getInterests()
  }, [])

  const getInterests = async () => {
    try {
      const interestsResult = await apiGet<Interest[]>('interests/get-active-interests', appState.identity.accessToken)
      setSelectedInterests(interestsResult)

      const archiveInterestsResult = await apiGet<Interest[]>(
        'interests/get-archived-interests',
        appState.identity.accessToken
      )
      setArchiveInterests(archiveInterestsResult)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const [interestValue, setInterestValue] = useState('')
  function addInterest() {
    setInterest(interestValue)
    setInterestValue('')
    getInterests()
  }

  async function setInterest(interestName: string) {
    await apiPost('interests', appState.identity.accessToken, {
      name: interestName,
    })

    getInterests()
  }

  async function archiveInterest(id: string, name: string) {
    await apiPost('interests/archive', appState.identity.accessToken, {
      InterestId: id,
    })

    notify(name + ' moved to archived list')
    getInterests()
  }

  const toasterId = useId()
  const { dispatchToast } = useToastController(toasterId)
  const notify = (message: string) =>
    dispatchToast(
      <Toast>
        <ToastTitle>Archived</ToastTitle>
        <ToastBody> {message}</ToastBody>
      </Toast>,
      { intent: 'success' }
    )
  const isMobile = window.matchMedia('(max-width: 390px)').matches

  return (
    <FluentProvider theme={teamsLightTheme}>
      <Toaster toasterId={toasterId} position="top-end" pauseOnHover pauseOnWindowBlur timeout={2000} />
      <div style={{ backgroundColor: '#EFF5F9' }}>
        <Header />

        <div style={{ marginLeft: '3rem', marginBottom: '1rem' }}>
          <Breadcrumb
            items={[
              { text: 'Home', key: 'home', href: '/#tab' },
              { text: 'Preferences', key: 'preferences', href: '/#preference' },
              { text: 'Manage Interests', key: 'manageInterests', isCurrentItem: true },
            ]}
          />
        </div>

        <DocumentCard
          style={{
            maxWidth: '100%',
            height: '3.5rem',
            padding: '1rem',
            border: '1px solid #F9F9F9',
            borderRadius: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '1rem',
            boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
            justifyContent: 'space-between',
            marginLeft: '2.5rem',
            marginRight: '2.5rem',
          }}
        >
          <div>
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <PrimaryButton text="Add Interest" />
              </DialogTrigger>
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Add Interest</DialogTitle>
                  <DialogContent>
                    <Input
                      placeholder="Interest Name"
                      type="text"
                      id="message"
                      name="message"
                      value={interestValue}
                      onChange={(e) => {
                        setInterestValue(e.target.value)
                      }}
                      autoComplete="off"
                    />
                  </DialogContent>

                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="primary" onClick={addInterest}>
                        Add
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>

          <div style={{ display: 'inline-flex' }}>
            <SearchBox
              placeholder={isMobile ? "" : "Search"}
              styles={{ root: { width: isMobile ? 70 : 90 } }}
              onEscape={(ev) => {
                logger.debug('Custom onEscape Called')
              }}
              onClear={(ev) => {
                logger.debug('Custom onClear Called')
              }}
              onChange={(_, newValue) => logger.debug('SearchBox onChange fired: ' + newValue)}
              onSearch={(newValue) => logger.debug('SearchBox onSearch fired: ' + newValue)}
            />
          </div>
        </DocumentCard>

        <div style={{ marginLeft: '2.5rem', marginTop: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {isHidden && <span style={{ fontWeight: '600', paddingLeft: '0.8rem' }}>Active Interests</span>}
            <span style={{ fontWeight: '600', display: isHidden ? 'none' : 'block', paddingLeft: '0.8rem' }}>
              Archived Interests
            </span>

            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Archive24Filled />
              <span style={{ paddingLeft: '0.5rem', paddingRight: '2.5rem', fontWeight: '600' }}>
                <ToggleButton checked={archive} onClick={ShowArchived}>
                  {!archive ? 'Show Archived' : 'Show Active'}
                </ToggleButton>
              </span>
            </span>
          </div>
          <div>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td valign="top" style={{ display: !isHidden ? 'none' : 'block', width: '100%' }}>
                    <DocumentCard
                      style={{
                        width: archive ? '95%' : 'auto',
                        maxWidth: '100%',
                        height: 'auto',
                        padding: '0.7rem',
                        border: '1px solid #F9F9F9',
                        borderRadius: '0.5rem',
                        display: 'flex',

                        flexDirection: 'column',
                        boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                        marginLeft: '0.3rem',
                        marginRight: '2.5rem',
                        marginTop: '0.5rem',
                        gap: '10px',
                      }}
                    >
                      {selectedInterests &&
                        _.sortBy(selectedInterests, [(interest) => interest.name]).map(
                          (item: Interest, index: number) => (
                            <>
                              <div>
                                <span style={{ fontWeight: '500' }}>{item.name}</span>

                                <small
                                  style={{
                                    color: '#616161',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingRight: '2rem',
                                  }}
                                >
                                  <span style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}>
                                    User Count: {item.userCount} / Meeting Count: {item.meetingCount}
                                  </span>
                                  <span>
                                    <span>
                                      <Dialog>
                                        <DialogTrigger disableButtonEnhancement>
                                          <DefaultButton style={{ padding: 0, margin: 0 }}>Archive</DefaultButton>
                                        </DialogTrigger>
                                        <DialogSurface>
                                          <DialogBody>
                                            <DialogTitle>Archive</DialogTitle>
                                            <DialogContent>
                                              Are you sure you want to Archive {item.name}? Once Archived it cannot be
                                              recovered.
                                            </DialogContent>
                                            <DialogActions>
                                              <DialogTrigger disableButtonEnhancement>
                                                <Button appearance="secondary">Cancel</Button>
                                              </DialogTrigger>
                                              <DialogTrigger disableButtonEnhancement>
                                                <Button
                                                  appearance="primary"
                                                  onClick={() => archiveInterest(item.id, item.name)}
                                                >
                                                  Archive
                                                </Button>
                                              </DialogTrigger>
                                            </DialogActions>
                                          </DialogBody>
                                        </DialogSurface>
                                      </Dialog>
                                    </span>
                                  </span>
                                </small>
                              </div>
                              {selectedInterests.length - 1 != index && (
                                <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
                              )}
                            </>
                          )
                        )}
                    </DocumentCard>
                  </td>
                  <td style={{ display: isHidden ? 'none' : 'block', width: '100%' }}>
                    <DocumentCard
                      style={{
                        width: archive ? '95%' : 'auto',

                        maxWidth: '100%',
                        height: 'auto',
                        padding: '0.7rem',
                        border: '1px solid #F9F9F9',
                        borderRadius: '0.5rem',
                        display: 'flex',

                        flexDirection: 'column',
                        boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                        marginLeft: '0.3rem',
                        marginRight: '2.5rem',
                        marginTop: '0.5rem',
                        opacity: 0.4,
                        gap: '10px',
                      }}
                    >
                      {archiveInterests?.map((item: Interest, index: number) => (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '500' }}>{item.name}</span>

                            <small
                              style={{
                                color: '#616161',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingRight: '2rem',
                              }}
                            >
                              <span>Some additional context about this interest goes here</span>
                            </small>
                          </div>
                          {archiveInterests.length - 1 != index && (
                            <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
                          )}
                        </>
                      ))}
                    </DocumentCard>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FluentProvider>
  )
}
